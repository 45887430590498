<template lang="html">
  <ClassificationResult :class="$style.indicator" :result="indicator">
    <IxRes>dlgCert.headers.{{ pillar }}_{{ indicator.name }}</IxRes>
  </ClassificationResult>
</template>

<script>
import ClassificationResult from './ClassificationResult'

export default {
  components: {
    ClassificationResult
  },
  props: {
    pillar: String,
    indicator: Object
  }
}
</script>

<style lang="scss" module>
.indicator {
  margin-bottom: 0.5em;
  cursor: pointer;

  transition: color 0.25s, background-color 0.25s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
</style>
