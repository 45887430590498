<template lang="html">
  <div :class="$style.preview">
    <div :class="$style.pillars">
      <PillarResult
        v-for="name in ['ecology', 'economy', 'social']" :key="name"
        :class="$style.pillar"
        :name="name" :pillar="data[`${name}Result`]"
      />
      <!-- <PillarResult name="management" :pillar="data.managementResult" /> -->
    </div>
  </div>
</template>

<script>
import PillarResult from './PillarResult'

export default {
  components: {
    PillarResult
  },
  props: {
    data: Object
  }
}
</script>

<style lang="scss" module>
.preview {
  display: flex;
  flex-direction: column;

  .pillars {
    display: flex;
  }

  .pillar {
    // flex: 1;
    width: 25%;
  }
}
</style>
