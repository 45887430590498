<template lang="html">
  <div :class="{[$style.pillar]: true, [$style[name]]: true}">
    <div :class="$style.top">
      <h4 :class="$style.header">
        <IxRes>dlgCert.headers.{{ name }}</IxRes>
      </h4>

      <div :class="$style.indicators">
        <IndicatorResult
          v-for="indicator in indicators" :key="indicator.name"
          :pillar="name"
          :indicator="indicator"
          @click.native="$router.push({name: 'indicatorPreview', params: {pillar: name, indicator: indicator.name}})"
        />
      </div>
    </div>

    <ClassificationResult :class="$style.total" :result="pillar">
      <IxRes>dlgCert.certificatePreview.labels.pillarClassification</IxRes>
    </ClassificationResult>
  </div>
</template>

<script>
import IndicatorResult from './IndicatorResult'
import ClassificationResult from './ClassificationResult'

export default {
  components: {
    IndicatorResult,
    ClassificationResult
  },
  props: {
    name: String,
    pillar: Object
  },
  computed: {
    indicators () {
      return Object.keys(this.pillar)
        .filter(x => x !== 'detailedClassification' && x !== 'error')
        .map(key => ({
          name: key.replace(/Result$/, ''),
          ...this.pillar[key]
        }))
    }
  }
}
</script>

<style lang="scss" module>
.pillar {
  margin: 1em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: white;

  &.ecology {
    background-color: var(--pillar-ecology);
  }

  &.economy {
    background-color: var(--pillar-economy);
  }

  &.social {
    background-color: var(--pillar-social);
  }
}

.header {
  margin: 0;
  margin-right: 0.5em;
  font-weight: 100;
  font-size: 3em;
  line-height: 0.5em;
  cursor: default;

  display: flex;
  justify-content: flex-end;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-transform: uppercase;
}

.top {
  display: flex;
}

.indicators {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.total {
  margin-top: 1em;
  font-size: 2em;
  padding: 0.25em;
}
</style>
